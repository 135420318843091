@import (reference) "../../../styles/colors.less";

.email-success-toast {
	align-items: center;
	background: #EBF8DD;
	border-radius: 3px;
	border: 1px solid @success;
	box-shadow: 0 0 6px 0 rgba(139,139,139,0.10);
	display: flex;
	justify-content: center;
	padding: 20px;

	&-icon {
	}

	&-text {
		color: #8E8E8E;
		font-size: 12px;
		letter-spacing: 0;
		margin-left: 12px;
	}
}